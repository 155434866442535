/**
 * DO NOT EDIT, generated automatically
 */

:root {
  --navigation-background-color: rgba(16, 16, 17, 0.5);
  --icon-size-small: 18px;
  --icon-size-large: 48px;
  --icon-size-medium: 24px;
  --color-white: #ececec;
  --color-link-blue: #176cc1;
  --color-black-100: #222831;
  --color-charcoal-100: #30475e;
  --color-beige-100: #c1a57b;
  --navigation-color: var(--color-white);
  --icon-color: var(--color-black-100);
  --divider-color: var(--color-black-100);
  --divider-background-color: var(--color-beige-100);
  --contact-link-color: var(--color-beige-100);
  --button-color: var(--color-black-100);
  --button-background-color: var(--color-beige-100);
}
