@font-face {
  font-family: 'Satoshi';
  src: url('/fonts/Satoshi-Variable.woff2') format('woff2-variations');
  font-weight: 100 900;
}

html,
body {
  background: var(--color-white);
}

html {
  -webkit-text-size-adjust: 100%;
  font-family: Satoshi, sans-serif;
  line-height: 1.15;
  color: var(--color-black-100);
}

body {
  margin: 0;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
