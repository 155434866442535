body {
  min-height: 100vh;
}

.h1 {
  font-weight: 300;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: -0.03em;

  @media (min-width: 800px) {
    font-size: 64px;
    line-height: 83px;
  }
}

.h2 {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.03em;

  @media (min-width: 800px) {
    font-size: 24px;
    line-height: 31px;
  }
}

.hero-1 {
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.02em;

  @media (min-width: 800px) {
    font-size: 24px;
    line-height: 33px;
  }
}

.hero-2 {
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: -0.03em;

  @media (min-width: 800px) {
    font-size: 44px;
    line-height: 61px;
  }
}
